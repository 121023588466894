<header class="__header u-margin-bottom" *ngIf="header; else headerContent">
    <div>
        <ng-container *ngTemplateOutlet="headerTop"></ng-container>
        <div class="u-flex-row">
            <div [innerHtml]="headerText"></div>
            <ng-content select="[card-header-info]"></ng-content>
        </div>
        <p *ngIf="sub" class="u-color-muted">{{ sub }}</p>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="headerRight"></ng-container>
</header>
<main class="__main u-w100p {{ mainClass }}">
    <ng-content></ng-content>
</main>
<footer class="u-margin-top-double">
    <ng-content select="[card-footer]"></ng-content>
</footer>

<ng-template #headerContent>
    <header class="__header u-margin-bottom">
        <ng-container *ngTemplateOutlet="headerTop"></ng-container>
        <ng-container *ngTemplateOutlet="content"></ng-container>
        <ng-container *ngTemplateOutlet="headerRight"></ng-container>
    </header>
</ng-template>

<ng-template #content>
    <ng-content select="[card-header]"></ng-content>
</ng-template>

<ng-template #headerRight>
    <ng-content select="[card-header-right]"></ng-content>
</ng-template>

<ng-template #headerTop>
    <ng-content select="[card-header-top]"></ng-content>
</ng-template>
